<template>
  <div class="pa-4 backcolor_green_lighten-5">
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-card tile class="d-flex flex-wrap pa-2">
          <v-card-text>
            <v-subheader
              >チェックした曜日で毎週更新したい時に使用してください。<br />※該当曜日は基本設定の内容より優先します。</v-subheader
            >
            <div v-for="item in weekItems" :key="item.index">
              <v-checkbox
                v-model="item.checked"
                :label="item.name"
                @change="onChange"
                hide-details
              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card tile class="d-flex flex-wrap pa-2">
          <v-card-text>
            <v-subheader
              >チェックした日付で毎月繰り返し更新したい時に使用してください。<br />※該当日付は曜日設定の内容より優先します。</v-subheader
            >
            <div v-for="item in dayItems" :key="item.index">
              <v-checkbox
                v-model="item.checked"
                :label="item.name"
                @change="onChangeDate"
                hide-details
              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  watch,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["set_weeks", "set_days"],
  setup(props, ctx) {
    const childUpdateContentsRepository = repositoryFactory.get(
      "childUpdateContents"
    );
    const state = reactive({
      weekItems: [
        {
          index: 1,
          name: "日曜日",
          checked: false,
        },
        {
          index: 2,
          name: "月曜日",
          checked: false,
        },
        {
          index: 3,
          name: "火曜日",
          checked: false,
        },
        {
          index: 4,
          name: "水曜日",
          checked: false,
        },
        {
          index: 5,
          name: "木曜日",
          checked: false,
        },
        {
          index: 6,
          name: "金曜日",
          checked: false,
        },
        {
          index: 7,
          name: "土曜日",
          checked: false,
        },
      ],
      dayItems: [],
    });

    const init = () => {
      for (let i = 1; i <= 31; i++) {
        state.dayItems.push({
          index: 7 + i,
          name: "" + i + "日",
          checked: false,
        });
      }
    };

    init();

    const onChange = () => {
      ctx.emit("select_week", state.weekItems);
    };

    const onChangeDate = () => {
      ctx.emit("select_day", state.dayItems);
    };

    state.weekItems.forEach((item) => {
      if (props.set_weeks.includes(item.index - 1)) {
        item.checked = true;
      }
    });

    state.dayItems.forEach((item) => {
      console.log(item.index, props.set_days);
      if (props.set_days.includes(item.index - 7)) {
        item.checked = true;
      }
    });

    return {
      setting,
      ...toRefs(state),
      onChange,
      onChangeDate,
    };
  },
});
</script>

<style scoped>
.backcolor_green_lighten-5 {
  background-color: #e8f5e9;
}
</style>
